<template>
  <div class="branches">
    <h3 class="title fs32 pb30 m0a">{{ title }}</h3>
    <p class="desc fs16 pb30 m0a">{{ desc }}</p>
    <div class="branches__inner">
      <yandex-map
        :coords="center"
        :zoom="zoom"
        :settings="settings"
      >
        <ymap-marker
          v-for="cord in coords"
          :coords="cord.cord"
          :key="cord.id"
          :markerId="cord.id"
          @click.self="onFocus(cord.cord)"
        />
      </yandex-map>
      <div class="branches__box">
        <BranchSelect
          class="branches__select"
          :options="filteredOptions"
          v-model="defaultSelected"
        />
        <ul class="branches__list">
          <li
            class="branches__list-item"
            v-for="cord in filteredBranches"
            :key="cord.cord"
            @click="onFocus(cord.cord)"
            :class="center === cord.cord ? 'active' : ''"
          >
            <img
              class="city"
              src="@/assets/img/icons/location-dark.svg"
              alt=""
            />
            <div class="branches__list-info">
              <span class="city">
                {{ $t(cord.city) }}
              </span>
              <span class="street">{{ $t(cord.street) }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import BranchSelect from '../UI/BranchSelect.vue';
import branches from '../../data/branches';
export default {
  components: { yandexMap, ymapMarker, BranchSelect },
  props: {
    title: String,
    desc: String
  },
  data() {
    return {
      zoom: 12,
      center: [40.196706, 44.565861],
      defaultSelected: '',
      selectedRegions: [],
      settings: {
        apiKey: 'a65ac035-8bab-4c1e-8d23-1f8a3ae240e8'
      },
      coords: branches
    };
  },
  methods: {
    onFocus(cords) {
      this.center = cords;
      setTimeout(() => {
        this.zoom = 18;
      }, 700);
    },
    balloonTemplate(
      city,
      street,
    ) {
      return `
        <div class="balloon-info">
          <div class="info">
          <span class="first">
          <span class="bg-icon city-icon" ></span>
          ${city}</span>
          <span class="secondary">${street}</span>
          </div>
        </div>
      `;
    },
    getRegions() {
      this.selectedRegions = [...new Set(this.coords.map((cord) => JSON.stringify({ id: this.$t(cord.region.id), label: this.$t(cord.region.label) })))].map(reg => JSON.parse(reg));
    },
    getImage(path) {
      return path;
    }
  },
  mounted() {
    this.getRegions();
    this.defaultSelected = this.selectedRegions[0];
  },
  computed: {
    filteredOptions() {
      return this.selectedRegions.map(reg => reg);
    },
    filteredBranches() {
      return this.coords.filter(coord => {
        if (this.$t(coord.region.id) === this.defaultSelected.id) {
          return coord;
        }
      });
    }

  }
};
</script>

<style>
.branches {
  padding-top: 35px;
  text-align: center;
}

.branches__inner {
  display: flex;
}

.ymap-container {
  height: 600px;
  max-width: 100%;
  width: 70%;
}

.ymaps-2-1-79-search {
  display: none;
}

.ymaps-2-1-79-controls__toolbar_right {
  display: none;
}

.ymaps-2-1-79-controls__control {
  display: none;
}

.ymaps-2-1-79-controls__bottom {
  display: none;
}

.ymaps-2-1-79-ground-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.ymaps-2-1-79-svg-icon {
  background-image: url('../../assets/img/icons/map-pin.png') !important;
  list-style-image: url('../../assets/img/icons/map-pin.png') !important;
  background-repeat: no-repeat;
  background-size: contain !important;
}

.ymaps-2-1-79-balloon {
  height: 150px;
  width: 250px;
  box-shadow: 0px 4px 16px rgba(126, 126, 126, 0.12);
  border-radius: 10px;
}

.ymaps-2-1-79-balloon__layout {
  height: 170px !important;
  width: 250px !important;
  box-shadow: 0px 4px 16px rgba(126, 126, 126, 0.12);
  border-radius: 10px;
}

.ymaps-2-1-79-balloon__close-button {
  padding: 0px 7px;
  background-image: url('../../assets/img/icons/balloon-close.png') !important;
}

.ymaps-2-1-79-balloon__tail {
  left: 110px !important;
}

.ymaps-2-1-79-search {
  display: none !important;
}

.ymaps-2-1-79-balloon {
  top: -192px !important;
  left: -110px !important;
}

.ymaps-2-1-79-image {
  display: none;
}

.balloon-info {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  color: var(--teamBlue);
}

.info {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.info:not(:last-of-type) {
  margin-bottom: 10px;
}

.first {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 15px;
}

.bg-icon {
  background-repeat: no-repeat;
  background-size: cover;
  width: 15px;
  height: 18px;
  display: inline-block;
}

.city-icon {
  background-image: url('../../assets/img/icons/location-dark.svg');
}

.days-icon {
  background-image: url('../../assets/img/icons/time.svg');
}

.secondary {
  padding-left: 30px;
}

.branches__box {
  width: 30%;
  background-color: #fff;
  padding: 12px;
}

.branches__list {
  max-width: 330px;
  max-height: 450px;
  overflow-y: auto;
}

.branches__select {
  max-width: 330px;
  margin-bottom: 25px;
}

.branches__list-item {
  background-color: #f4f4f4;
  margin-bottom: 16px;
  border-radius: 12px;
  padding: 10px 25px;
  margin-right: 10px;
  display: flex;
  gap: 18px;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}

.branches__list-item:hover {
  background-color: var(--teamCyan);
}

.branches__list-item.active {
  background-color: var(--teamCyan);
}

.branches__list-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.branches__list-info .city {
  font-weight: bold;
}

.branches__list-info .street {
  font-size: 14px;
}

.branches__list::-webkit-scrollbar {
  width: 8px;
}

.branches__list .work-schedule {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.branches__list::-webkit-scrollbar-track {
  background: var(--teamSand);
}

.branches__list::-webkit-scrollbar-thumb {
  background-color: var(--teamCyan);
  border-radius: 20px;
  border: 3px solid var(--teamCyan);
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .branches {
    padding-top: 20px;
  }

  .branches__inner {
    flex-direction: column;
  }

  .ymap-container {
    height: 350px;
    width: 100%;
  }

  .ymaps-2-1-79-balloon {
    top: -352px !important;
    left: 110px !important;
  }

  .balloon-info {
    padding: 0;
  }

  .branches__box {
    width: 100%;
  }

  .branches__list {
    margin: 0 auto;
    height: 350px;
    padding-right: 10px;
  }

  .branches__list-info .city {
    font-size: 14px;
  }

  .branches__list-info .street {
    font-size: 12px;
  }

  .branches__list .work-schedule {
    font-size: 11px;
  }

  .updated {
    font-size: 12px;
  }
}
</style>
