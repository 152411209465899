const branches = [
  // {
  //   id: '1',
  //   cord: [40.194806, 44.482012],
  //   phone: '+37495288221',
  //   city: 'branches.branch1.city',
  //   days: {
  //     monFry: 'branches.branch1.days.monFry.text',
  //     satSun: 'branches.branch1.days.satSun.text',
  //   },
  //   hours: {
  //     monFry: 'branches.branch1.days.monFry.hours',
  //     satSun: 'branches.branch1.days.satSun.hours',
  //   },
  //   street: 'branches.branch1.street',
  //   region: {
  //     id: 'branches.regions.yerevan.id',
  //     label: 'branches.regions.yerevan.label',
  //   },
  // },
  {
    id: '2',
    cord: [40.180965, 44.526494],
    city: 'branches.branch2.city',
    days: {
      monFry: 'branches.branch2.days.monFry.text',
      satSun: 'branches.branch2.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch2.days.monFry.hours',
      satSun: 'branches.branch2.days.satSun.hours',
    },
    street: 'branches.branch2.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '3',
    cord: [40.759606, 44.907322],
    city: 'branches.branch3.city',
    days: {
      monFry: 'branches.branch3.days.monFry.text',
      satSun: 'branches.branch3.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch3.days.monFry.hours',
      satSun: 'branches.branch3.days.satSun.hours',
    },
    street: 'branches.branch3.street',
    region: {
      id: 'branches.regions.tavush.id',
      label: 'branches.regions.tavush.label',
    },
  },
  {
    id: '4',
    cord: [40.77815, 44.926268],
    city: 'branches.branch4.city',
    days: {
      monFry: 'branches.branch4.days.monFry.text',
      satSun: 'branches.branch4.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch4.days.monFry.hours',
      satSun: 'branches.branch4.days.satSun.hours',
    },
    street: 'branches.branch4.street',
    region: {
      id: 'branches.regions.tavush.id',
      label: 'branches.regions.tavush.label',
    },
  },
  {
    id: '5',
    cord: [39.838582, 45.666891],
    city: 'branches.branch5.city',
    days: {
      monFry: 'branches.branch5.days.monFry.text',
      satSun: 'branches.branch5.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch5.days.monFry.hours',
      satSun: 'branches.branch5.days.satSun.hours',
    },
    street: 'branches.branch5.street',
    region: {
      id: 'branches.regions.vayots_dzor.id',
      label: 'branches.regions.vayots_dzor.label',
    },
  },
  {
    id: '6',
    cord: [40.264036, 44.677647],
    city: 'branches.branch6.city',
    days: {
      monFry: 'branches.branch6.days.monFry.text',
      satSun: 'branches.branch6.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch6.days.monFry.hours',
      satSun: 'branches.branch6.days.satSun.hours',
    },
    street: 'branches.branch6.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '7',
    cord: [39.755447, 45.339808],
    city: 'branches.branch7.city',
    days: {
      monFry: 'branches.branch7.days.monFry.text',
      satSun: 'branches.branch7.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch7.days.monFry.hours',
      satSun: 'branches.branch7.days.satSun.hours',
    },
    street: 'branches.branch7.street',
    region: {
      id: 'branches.regions.vayots_dzor.id',
      label: 'branches.regions.vayots_dzor.label',
    },
  },
  {
    id: '8',
    cord: [40.339202, 44.268645],
    city: 'branches.branch8.city',
    days: {
      monFry: 'branches.branch8.days.monFry.text',
      satSun: 'branches.branch8.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch8.days.monFry.hours',
      satSun: 'branches.branch8.days.satSun.hours',
    },
    street: 'branches.branch8.street',
    region: {
      id: 'branches.regions.aragatsotn.id',
      label: 'branches.regions.aragatsotn.label',
    },
  },
  {
    id: '9',
    cord: [40.186775, 44.603342],
    city: 'branches.branch9.city',
    days: {
      monFry: 'branches.branch9.days.monFry.text',
      satSun: 'branches.branch9.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch9.days.monFry.hours',
      satSun: 'branches.branch9.days.satSun.hours',
    },
    street: 'branches.branch9.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '10',
    cord: [40.193094, 44.586681],
    city: 'branches.branch10.city',
    days: {
      monFry: 'branches.branch10.days.monFry.text',
      satSun: 'branches.branch10.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch10.days.monFry.hours',
      satSun: 'branches.branch10.days.satSun.hours',
    },
    street: 'branches.branch10.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '11',
    cord: [40.262221, 44.679325],
    city: 'branches.branch11.city',
    days: {
      monFry: 'branches.branch11.days.monFry.text',
      satSun: 'branches.branch11.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch11.days.monFry.hours',
      satSun: 'branches.branch11.days.satSun.hours',
    },
    street: 'branches.branch11.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '12',
    cord: [40.194905, 44.609874],
    city: 'branches.branch12.city',
    days: {
      monFry: 'branches.branch12.days.monFry.text',
      satSun: 'branches.branch12.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch12.days.monFry.hours',
      satSun: 'branches.branch12.days.satSun.hours',
    },
    street: 'branches.branch12.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '13',
    cord: [40.30187, 44.378767],
    city: 'branches.branch13.city',
    days: {
      monFry: 'branches.branch13.days.monFry.text',
      satSun: 'branches.branch13.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch13.days.monFry.hours',
      satSun: 'branches.branch13.days.satSun.hours',
    },
    street: 'branches.branch13.street',
    region: {
      id: 'branches.regions.aragatsotn.id',
      label: 'branches.regions.aragatsotn.label',
    },
  },
  {
    id: '14',
    cord: [39.689691, 45.471856],
    city: 'branches.branch14.city',
    days: {
      monFry: 'branches.branch14.days.monFry.text',
      satSun: 'branches.branch14.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch14.days.monFry.hours',
      satSun: 'branches.branch14.days.satSun.hours',
    },
    street: 'branches.branch14.street',
    region: {
      id: 'branches.regions.vayots_dzor.id',
      label: 'branches.regions.vayots_dzor.label',
    },
  },
  {
    id: '15',
    cord: [40.191673, 44.58845],
    city: 'branches.branch15.city',
    days: {
      monFry: 'branches.branch15.days.monFry.text',
      satSun: 'branches.branch15.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch15.days.monFry.hours',
      satSun: 'branches.branch15.days.satSun.hours',
    },
    street: 'branches.branch15.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '16',
    cord: [40.739758, 44.853351],
    city: 'branches.branch16.city',
    days: {
      monFry: 'branches.branch16.days.monFry.text',
      satSun: 'branches.branch16.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch16.days.monFry.hours',
      satSun: 'branches.branch16.days.satSun.hours',
    },
    street: 'branches.branch16.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '17',
    cord: [40.752287, 43.83754],
    city: 'branches.branch17.city',
    days: {
      monFry: 'branches.branch17.days.monFry.text',
      satSun: 'branches.branch17.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch17.days.monFry.hours',
      satSun: 'branches.branch17.days.satSun.hours',
    },
    street: 'branches.branch17.street',
    region: {
      id: 'branches.regions.shirak.id',
      label: 'branches.regions.shirak.label',
    },
  },
  // {
  //   id: '18',
  //   cord: [40.162533, 44.50645],
  //   city: 'branches.branch18.city',
  //   days: {
  //     monFry: 'branches.branch18.days.monFry.text',
  //     satSun: 'branches.branch18.days.satSun.text',
  //   },
  //   hours: {
  //     monFry: 'branches.branch18.days.monFry.hours',
  //     satSun: 'branches.branch18.days.satSun.hours',
  //   },
  //   street: 'branches.branch18.street',
  //   region: {
  //     id: 'branches.regions.yerevan.id',
  //     label: 'branches.regions.yerevan.label',
  //   },
  // },
  {
    id: '19',
    cord: [40.736404, 43.79646],
    city: 'branches.branch19.city',
    days: {
      monFry: 'branches.branch19.days.monFry.text',
      satSun: 'branches.branch19.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch19.days.monFry.hours',
      satSun: 'branches.branch19.days.satSun.hours',
    },
    street: 'branches.branch19.street',
    region: {
      id: 'branches.regions.shirak.id',
      label: 'branches.regions.shirak.label',
    },
  },
  {
    id: '20',
    cord: [40.255003, 44.495677],
    city: 'branches.branch20.city',
    days: {
      monFry: 'branches.branch20.days.monFry.text',
      satSun: 'branches.branch20.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch20.days.monFry.hours',
      satSun: 'branches.branch10.days.satSun.hours',
    },
    street: 'branches.branch20.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '21',
    cord: [40.124678, 44.696853],
    city: 'branches.branch21.city',
    days: {
      monFry: 'branches.branch21.days.monFry.text',
      satSun: 'branches.branch21.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch21.days.monFry.hours',
      satSun: 'branches.branch21.days.satSun.hours',
    },
    street: 'branches.branch21.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '22',
    cord: [40.960489, 44.634322],
    city: 'branches.branch22.city',
    days: {
      monFry: 'branches.branch22.days.monFry.text',
      satSun: 'branches.branch22.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch22.days.monFry.hours',
      satSun: 'branches.branch22.days.satSun.hours',
    },
    street: 'branches.branch22.street',
    region: {
      id: 'branches.regions.lori.id',
      label: 'branches.regions.lori.label',
    },
  },
  {
    id: '23',
    cord: [40.411559, 45.471135],
    city: 'branches.branch23.city',
    days: {
      monFry: 'branches.branch23.days.monFry.text',
      satSun: 'branches.branch23.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch23.days.monFry.hours',
      satSun: 'branches.branch23.days.satSun.hours',
    },
    street: 'branches.branch23.street',
    region: {
      id: 'branches.regions.gegharkunik.id',
      label: 'branches.regions.gegharkunik.label',
    },
  },
  {
    id: '24',
    cord: [39.682218, 45.486445],
    city: 'branches.branch24.city',
    days: {
      monFry: 'branches.branch24.days.monFry.text',
      satSun: 'branches.branch24.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch24.days.monFry.hours',
      satSun: 'branches.branch24.days.satSun.hours',
    },
    street: 'branches.branch24.street',
    region: {
      id: 'branches.regions.vayots_dzor.id',
      label: 'branches.regions.vayots_dzor.label',
    },
  },
  {
    id: '25',
    cord: [39.48107, 46.27923],
    city: 'branches.branch25.city',
    days: {
      monFry: 'branches.branch25.days.monFry.text',
      satSun: 'branches.branch25.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch25.days.monFry.hours',
      satSun: 'branches.branch25.days.satSun.hours',
    },
    street: 'branches.branch25.street',
    region: {
      id: 'branches.regions.syunik.id',
      label: 'branches.regions.syunik.label',
    },
  },
  {
    id: '26',
    cord: [39.204386, 46.444177],
    city: 'branches.branch26.city',
    days: {
      monFry: 'branches.branch26.days.monFry.text',
      satSun: 'branches.branch26.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch26.days.monFry.hours',
      satSun: 'branches.branch26.days.satSun.hours',
    },
    street: 'branches.branch26.street',
    region: {
      id: 'branches.regions.syunik.id',
      label: 'branches.regions.syunik.label',
    },
  },
  {
    id: '27',
    cord: [40.619171, 44.951778],
    city: 'branches.branch27.city',
    days: {
      monFry: 'branches.branch27.days.monFry.text',
      satSun: 'branches.branch27.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch27.days.monFry.hours',
      satSun: 'branches.branch27.days.satSun.hours',
    },
    street: 'branches.branch27.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '28',
    cord: [41.221398, 44.893938],
    city: 'branches.branch28.city',
    days: {
      monFry: 'branches.branch28.days.monFry.text',
      satSun: 'branches.branch28.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch28.days.monFry.hours',
      satSun: 'branches.branch28.days.satSun.hours',
    },
    street: 'branches.branch28.street',
    region: {
      id: 'branches.regions.tavush.id',
      label: 'branches.regions.tavush.label',
    },
  },
  // {
  //   id: '29',
  //   cord: [40.170411, 44.525255],
  //   city: 'branches.branch29.city',
  //   days: {
  //     monFry: 'branches.branch29.days.monFry.text',
  //     satSun: 'branches.branch29.days.satSun.text',
  //   },
  //   hours: {
  //     monFry: 'branches.branch29.days.monFry.hours',
  //     satSun: 'branches.branch29.days.satSun.hours',
  //   },
  //   street: 'branches.branch29.street',
  //   region: {
  //     id: 'branches.regions.yerevan.id',
  //     label: 'branches.regions.yerevan.label',
  //   },
  // },
  {
    id: '30',
    cord: [40.209194, 44.46483],
    city: 'branches.branch30.city',
    days: {
      monFry: 'branches.branch30.days.monFry.text',
      satSun: 'branches.branch30.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch30.days.monFry.hours',
      satSun: 'branches.branch30.days.satSun.hours',
    },
    street: 'branches.branch30.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  // {
  //   id: '31',
  //   cord: [40.195742, 44.566495],
  //   city: 'branches.branch31.city',
  //   days: {
  //     monFry: 'branches.branch31.days.monFry.text',
  //     satSun: 'branches.branch31.days.satSun.text',
  //   },
  //   hours: {
  //     monFry: 'branches.branch31.days.monFry.hours',
  //     satSun: 'branches.branch31.days.satSun.hours',
  //   },
  //   street: 'branches.branch31.street',
  //   region: {
  //     id: 'branches.regions.yerevan.id',
  //     label: 'branches.regions.yerevan.label',
  //   },
  // },
  // {
  //   id: '32',
  //   cord: [40.202749, 44.544595],
  //   city: 'branches.branch32.city',
  //   days: {
  //     monFry: 'branches.branch32.days.monFry.text',
  //     satSun: 'branches.branch32.days.satSun.text',
  //   },
  //   hours: {
  //     monFry: 'branches.branch32.days.monFry.hours',
  //     satSun: 'branches.branch32.days.satSun.hours',
  //   },
  //   street: 'branches.branch32.street',
  //   region: {
  //     id: 'branches.regions.yerevan.id',
  //     label: 'branches.regions.yerevan.label',
  //   },
  // },
  // {
  //   id: '33',
  //   cord: [40.787503, 43.848371],
  //   city: 'branches.branch33.city',
  //   days: {
  //     monFry: 'branches.branch33.days.monFry.text',
  //     satSun: 'branches.branch33.days.satSun.text',
  //   },
  //   hours: {
  //     monFry: 'branches.branch33.days.monFry.hours',
  //     satSun: 'branches.branch33.days.satSun.hours',
  //   },
  //   street: 'branches.branch33.street',
  //   region: {
  //     id: 'branches.regions.shirak.id',
  //     label: 'branches.regions.shirak.label',
  //   },
  // },
  // {
  //   id: '34',
  //   cord: [41.204701, 44.318209],
  //   city: 'branches.branch34.city',
  //   days: {
  //     monFry: 'branches.branch34.days.monFry.text',
  //     satSun: 'branches.branch34.days.satSun.text',
  //   },
  //   hours: {
  //     monFry: 'branches.branch34.days.monFry.hours',
  //     satSun: 'branches.branch34.days.satSun.hours',
  //   },
  //   street: 'branches.branch34.street',
  //   region: {
  //     id: 'branches.regions.lori.id',
  //     label: 'branches.regions.lori.label',
  //   },
  // },
];

export default branches;
